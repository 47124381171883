
.ciot-cloud svg {
  -webkit-animation: ciot-cloud-icon-rotate 3.6s linear infinite;
          animation: ciot-cloud-icon-rotate 3.6s linear infinite;
}

.ciot-cloud-icon5 {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
        -webkit-animation: ciot-cloud-icon-rain 2000ms infinite ease-out;
                animation: ciot-cloud-icon-rain 2000ms infinite ease-out; 
}

.ciot-cloud-icon4 {
  -webkit-animation-delay: 600ms;
          animation-delay: 600ms;
        -webkit-animation: ciot-cloud-icon-rain 3600ms infinite ease-out;
                animation: ciot-cloud-icon-rain 3600ms infinite ease-out;
}

.ciot-cloud-icon7 {
  -webkit-animation-delay: 1000ms;
          animation-delay: 1000ms;
        -webkit-animation: ciot-cloud-icon-rain 1200ms infinite ease-out;
                animation: ciot-cloud-icon-rain 1200ms infinite ease-out; 
}

.ciot-cloud-icon2 {
  -webkit-animation-delay: 1400ms;
          animation-delay: 1400ms;
        -webkit-animation: ciot-cloud-icon-rain 3000ms infinite ease-out;
                animation: ciot-cloud-icon-rain 3000ms infinite ease-out;      
}

.ciot-cloud-icon1 {
  -webkit-animation-delay: 2000ms;
          animation-delay: 2000ms;
        -webkit-animation: ciot-cloud-icon-rain 1500ms infinite ease-out;
                animation: ciot-cloud-icon-rain 1500ms infinite ease-out; 
}

.ciot-cloud-icon9 {
  -webkit-animation-delay: 2400ms;
          animation-delay: 2400ms;
        -webkit-animation: ciot-cloud-icon-rain 2400ms infinite ease-out;
                animation: ciot-cloud-icon-rain 2400ms infinite ease-out; 
}

.ciot-cloud-icon3 {
  -webkit-animation-delay: 2800ms;
          animation-delay: 2800ms;
        -webkit-animation: ciot-cloud-icon-rain 2800ms infinite ease-out;
                animation: ciot-cloud-icon-rain 2800ms infinite ease-out;
}

.ciot-cloud-icon8 {
  -webkit-animation-delay: 3200ms;
          animation-delay: 3200ms;
        -webkit-animation: ciot-cloud-icon-rain 1700ms infinite ease-out;
                animation: ciot-cloud-icon-rain 1700ms infinite ease-out; 
}

.ciot-cloud-icon6 {
  -webkit-animation-delay: 3600ms;
          animation-delay: 3600ms;
        -webkit-animation: ciot-cloud-icon-rain 2800ms infinite ease-out;
                animation: ciot-cloud-icon-rain 2800ms infinite ease-out;
}

@-webkit-keyframes ciot-cloud-icon-rain {
  0% {
    opacity: 1.0;
    -webkit-transform: translate(25px, 0);
            transform: translate(25px, 0);
  } 
  100% {
    opacity: 0.0;
    -webkit-transform: translate(-25px, 220px);
            transform: translate(-25px, 220px);
  }
}

@keyframes ciot-cloud-icon-rain {
  0% {
    opacity: 1.0;
    -webkit-transform: translate(25px, 0);
            transform: translate(25px, 0);
  } 
  100% {
    opacity: 0.0;
    -webkit-transform: translate(-25px, 200px);
            transform: translate(-25px, 200px);
  }
}

@-webkit-keyframes ciot-cloud-icon-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}

@keyframes ciot-cloud-icon-rotate {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
            transform: rotate(359deg);
  }
}